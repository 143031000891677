import { TableCell, TableRow } from "@mui/material";

import { getDateFormat, getTimeFormat } from "../../../shared/helpers/timeFormat";
import { EditDealButton } from "./EditDealButton";
import { Deal } from "../deal.dto";


export const DealCard = (deal: Deal) => {
    const baseCurrency = deal.instrument.split('/')[0]
    const quotedCurrency = (deal.instrument.split('/')[1]).split('_')[0];

    return (
        <TableRow>
            <TableCell>{getDateFormat(new Date(deal.dealDateTime))}</TableCell>
            <TableCell>{getTimeFormat(new Date(deal.dealDateTime))}</TableCell>

            <TableCell>{deal.instrument}</TableCell>

            <TableCell>{deal.buyerCode}</TableCell>
            <TableCell>{deal.sellerCode}</TableCell>

            <TableCell>{new Number(deal.value).toLocaleString('ru')}</TableCell>

            <TableCell>{new Number(deal.rate).toLocaleString('ru', { minimumFractionDigits: 4 })}</TableCell>
            <TableCell>
                {
                    deal.valueDateTimeBase === deal.valueDateTimeQuoted
                        ? getDateFormat(new Date(deal.valueDateTimeBase))
                        : `${baseCurrency} ${getDateFormat(new Date(deal.valueDateTimeBase))} 
                            ${quotedCurrency} ${getDateFormat(new Date(deal.valueDateTimeQuoted))}`
                }
            </TableCell>
            <TableCell>{deal.counterparty}</TableCell>
            <TableCell>{new Number(deal.cbrfRate).toLocaleString('ru', { minimumFractionDigits: 4 })}</TableCell>
            <TableCell>
                {
                    deal.firstRate
                        ? new Number(deal.cbrfRate).toLocaleString('ru', { minimumFractionDigits: 4 })
                        : '--'
                }
            </TableCell>
            <TableCell>
                <EditDealButton id={deal.id} />
            </TableCell>
        </TableRow>
    )
}