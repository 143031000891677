
export const enum Counterparty {
    line = 'LINE',
    moex = 'MOEX'
}

export type FormDeal = {
    dealDateTime: string,

    valueDateTimeBase: string,
    valueDateTimeQuoted: string,

    instrument: string,
    buyerCode: string,
    sellerCode: string,

    value: number,
    rate: number,
    counterparty: Counterparty
}

export type Deal = FormDeal & {
    id: string,

    cbrfRate: number,
    firstRate?: number,

    employeeId?: string,
    provider?: string
}

export interface DealFilter {
    dealDateTimeStart?: string,
    dealDateTimeEnd?: string,

    instrument?: string[]
}

export type Instrument = {
    instrument: string,
    tenor: string,
    baseCurrency: string,
    quotedCurrency: string
}



export const CLEARINGS = [Counterparty.line, Counterparty.moex];

export const CURRENCIES = [
    'AUD', 'AZN', 'AMD', 'BYN', 'BGN', 'BRL', 'HUF', 'KRW', 'VND', 
    'HKD', 'GEL', 'DKK', 'AED', 'USD', 'EUR', 'EGP', 'INR', 'IDR', 
    'KZT', 'CAD', 'QAR', 'KGS', 'CNY', 'MDL', 'NZD', 'TMT', 'NOK', 
    'PLN', 'RON', 'XDR', 'RSD', 'SGD', 'TJS', 'THB', 'TRY', 'UZS', 
    'UAH', 'GBP', 'CZK', 'SEK', 'CHF', 'ZAR', 'JPY', '',
]